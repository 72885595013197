* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

button {
  border: none;
  background: none;
}

body {
  font-family: 'DM Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  
  scrollbar-width: none;  
  max-width: 100vw;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

h1, h2, h3, p, svg, img {
    user-select: none;
}

/* custom class sweet alert */
.sweet-custom-popup{
  display: flex !important;
  align-items: center !important;
}

.sweet-custom-icon{
  margin: unset !important;
}